import {SwipeableDrawer} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import React, {useState} from 'react';
import {SideList} from './SideList';
import ToolBarContainer from "../containers/ToolBarContainer";
import {useSelector} from "react-redux";
import {RootState} from "../rootReducer";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    list: {
      width: 250,
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
);

type StudyProps = {
  isQuestionsPrepared: boolean;
}

export const Study: React.FC<StudyProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [state, setState] = useState({
    left: false,
  });
  const settingStore = useSelector((state: RootState) => state.settings);

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({...state, [side]: open});
  };

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <SwipeableDrawer
        open={state.left}
        onClose={toggleDrawer('left', false)}
        onOpen={toggleDrawer('left', true)}
      >
        <SideList
          displayLanguage={settingStore.displayLanguage}
          toggleDrawer={toggleDrawer}/>
      </SwipeableDrawer>
      <main
        className={classes.content}
      >
        {props.isQuestionsPrepared ? props.children : <p/>}
      </main>
      <ToolBarContainer
        toggleDrawer={toggleDrawer}
      />
    </div>
  );
};
