import React, {useEffect, useState} from 'react';
import {Voice} from "../components/Voice";
import db from "../db";
import {SettingState, VoicePayload} from "../modules/setting/types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../rootReducer";
import {setVoice} from "../modules/setting/actions";

const VoiceContainer: React.FC = () => {

  const [voices, setVoices] = useState([]);
  const [japaneseVoices, setJapaneseVoices] = useState([]);
  const settingStore: SettingState = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch()

  useEffect(() => {
    const voices = window.speechSynthesis.getVoices();
    setVoices(voices.filter(p => p.lang.startsWith('en')));
    setJapaneseVoices(voices.filter(p => p.lang.startsWith('ja')));
  }, []);

  const saveVoice = (voice: VoicePayload): void => {
    dispatch(setVoice(voice));
    (async () => {
      await db.settings.update(1, voice);
    })();
  };

  return (
    <Voice
      voices={voices}
      japaneseVoices={japaneseVoices}
      handleSubmit={saveVoice}
      setting={settingStore}
    />
  );
};

export default VoiceContainer
