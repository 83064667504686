import React from 'react';
import useReactRouter from 'use-react-router';
import {ToolBar} from '../components/ToolBar';
import {CONSTANTS} from "../constants";
import {useDispatch, useSelector} from "react-redux";
import {showAnswer as showAnswerAction, speech} from "../modules/word/actions";
import {RootState} from "../rootReducer";

type ToolbarProps = {
  toggleDrawer: (side: string, open: boolean) => void,
}

const ToolBarContainer: React.FC<ToolbarProps> = (props) => {
  const {history, location} = useReactRouter();
  const dispatch = useDispatch()
  const wordStore = useSelector((state: RootState) => state.words);
  const settingStore = useSelector((state: RootState) => state.settings);

  const startStudy = () => {
    history.push(`/${settingStore.mode || CONSTANTS.SETTING.MODE.JA}`);
  };

  const showAnswer = async () => {
    await dispatch(showAnswerAction())
  };

  const speechText = () => {
    // TODO settingStore.language === 'ja' をメソッド化
    dispatch(speech(
      {
        language: settingStore.language,
        text: settingStore.language === 'ja' ? wordStore.mean.replace(/[…\/)～〜/\n]/gm, '、').replace(/兄ちゃん/gm, 'にいちゃん') : wordStore.text,
        voice: settingStore.language === 'ja' ? settingStore.japaneseVoice : settingStore.voice,
        rate: settingStore.language === 'ja' ? settingStore.japaneseRate : settingStore.rate,
      }
    ))
  };

  return (
    <ToolBar
      position="fixed"
      startStudy={startStudy}
      showAnswer={showAnswer}
      speechText={speechText}
      isHome={location.pathname === '/home'}
      toggleDrawer={props.toggleDrawer}
    />
  );
};

export default ToolBarContainer
