import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React from 'react';
import {CheckCircleIcon} from "./icons/CheckCircleIcon";
import useReactRouter from "use-react-router";
import {CONSTANTS} from "../constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      justifyContent: 'space-between',
    },
    licenseText: {
      color: '#fff',
      fontSize: '12px',
      paddingLeft: 0,
      paddingBottom: 60,
      listStyle: 'none',
      textAlign: 'center',
    },
    goToHome: {
      color: '#fff',
      padding: '20px 60px',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'determination',
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`
    },
    goToHomeText: {
      marginLeft: '8px',
    },
  }),
);

export const License: React.FC = () => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);

  const {history} = useReactRouter();

  const onClickGoToHome = () => {
    history.replace(CONSTANTS.PATH.HOME);
  }

  return (
    <div
      className={classes.root}
    >
      <ul
        className={classes.licenseText}
      >
        <li>
          MERCY font by MaxiGamer
        </li>
      </ul>

      <div
        className={classes.goToHome}
        onClick={onClickGoToHome}
      >
        <CheckCircleIcon/>
        <span className={classes.goToHomeText}>OK</span>
      </div>
    </div>
  );
};
