import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React, {useEffect, useState} from 'react';
import IconButton from "@material-ui/core/IconButton";
import {SpeechIcon} from "./icons/SpeechIcon";
import Typography from "@material-ui/core/Typography";
import {CheckCircleIcon} from "./icons/CheckCircleIcon";
import useReactRouter from "use-react-router";
import {CONSTANTS} from "../constants";
import clsx from "clsx";
import {SettingState} from "../modules/setting/types";
import {I18nText} from "./I18nText";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: CONSTANTS.COLOR.WHITE,
      fontFamily: 'determination, undertale-ja',
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
      justifyContent: 'space-between',
    },
    languageSelectWrapper: {
      padding: 8,
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
      marginBottom: 8,
    },
    languageSelect: {
      display: 'flex',
      padding: 16,
      justifyContent: 'space-around',
    },
    language: {
      whiteSpace: 'pre-wrap',
      paddingLeft: 28,
    },
    selectedLanguage: {
      backgroundImage: 'url(/images/soul.png)',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '24px 24px',
      backgroundPositionY: 'center',
    },
    english: {
      padding: 8,
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
      marginBottom: 8,
    },
    japanese: {
      padding: 8,
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
      marginBottom: 8,
    },
    title: {
      fontSize: '20px',
    },
    voices: {
      color: CONSTANTS.COLOR.WHITE,
      width: '100%',
      padding: 8,
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
      fontSize: '1.6rem',
      fontFamily: 'determination, undertale-ja',
      marginBottom: '20px',
      background: '#000',
    },
    rateWrapper: {
      display: 'flex',
      fontSize: 14,
    },
    rateRange: {
      flexGrow: 1,
      margin: '0 10px',
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 20,
    },
    buttonCaption: {
      marginTop: '-0.8rem',
      fontSize: '16px',
      fontFamily: 'determination, undertale-ja',
    },
    menuButton: {
      flexGrow: 1,
    },
    goToHome: {
      color: '#fff',
      padding: '20px 60px',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'determination, undertale-ja',
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`
    },
    goToHomeText: {
      marginLeft: '8px',
    },
  }),
);
const texts = {
  whichLanguage: {
    en: '(which language do you choose?)',
    ja: '（どっちの　はつおんを　きく？）',
  },
  english: {
    en: 'English',
    ja: 'えいごに\nする',
  },
  japanese: {
    en: 'Japanese',
    ja: 'にほんご\nですね',
  },
  voice: {
    en: 'voice',
    ja: 'おんせい',
  },
  speed: {
    en: 'speed',
    ja: 'スピード',
  },
  fast: {
    en: 'fast',
    ja: 'はやい',
  },
  slow: {
    en: 'slow',
    ja: 'おそい',
  },
  speech: {
    en: 'audition',
    ja: 'はつおんを　きく',
  },
}

type VoiceProps = {
  setting: SettingState,
  handleSubmit: Function,
  voices: SpeechSynthesisVoice[],
  japaneseVoices: SpeechSynthesisVoice[],
}

export const Voice: React.FC<VoiceProps> = (props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);

  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [voice, setVoice] = useState('');
  const [rate, setRate] = useState(1);
  const [japaneseVoice, setJapaneseVoice] = useState('');
  const [japaneseRate, setJapaneseRate] = useState(1);

  const {history} = useReactRouter();
  const {setting} = props

  useEffect(() => {
    setSelectedLanguage(setting.language)
    setVoice(setting?.voice)
    setRate(setting?.rate || 1)
    setJapaneseVoice(setting?.japaneseVoice)
    setJapaneseRate(setting?.japaneseRate || 1)
  }, [setting])

  const speech = (): void => {
    const synth = window.speechSynthesis;
    const utterThis = new SpeechSynthesisUtterance('You used the Dog Residue. The rest of your inventory filled up with Dog Residue.');
    const voices = speechSynthesis.getVoices()
    const selectedVoice: SpeechSynthesisVoice = voices.find(p => p.name === voice);
    utterThis.voice = selectedVoice || voices.find(p => p.lang.startsWith('en'))
    utterThis.lang = selectedVoice?.lang || utterThis.voice?.lang || 'en-US'
    utterThis.rate = rate || 1
    synth.cancel();
    synth.speak(utterThis);
  }

  const speechJapanese = (): void => {
    const synth = window.speechSynthesis;
    const utterThis = new SpeechSynthesisUtterance("イヌのおとしものを　つかった。もちものの　あきスペースが　ぜんぶ　イヌのおとしもので　うまった。");
    const voices = speechSynthesis.getVoices()
    const selectedVoice: SpeechSynthesisVoice = voices.find(p => p.name === japaneseVoice);
    utterThis.voice = selectedVoice || voices.find(p => p.lang.startsWith('ja'))
    utterThis.lang = selectedVoice?.lang || utterThis.voice?.lang || 'ja-JP'
    utterThis.rate = japaneseRate || 1
    synth.cancel();
    synth.speak(utterThis);
  }

  const onClickLanguage = (event: React.MouseEvent<HTMLInputElement>): void => {
    setSelectedLanguage(event.currentTarget.dataset.language)
  }

  const onChangeVoice = (event: React.FormEvent<HTMLSelectElement>): void => {
    setVoice(event.currentTarget.value)
  }

  const onChangeRate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRate(Number(event.currentTarget.value) || 1)
  }

  const onChangeJapaneseVoice = (event: React.FormEvent<HTMLSelectElement>): void => {
    setJapaneseVoice(event.currentTarget.value)
  }

  const onChangeJapaneseRate = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setJapaneseRate(Number(event.currentTarget.value) || 1)
  }

  const onClickGoToHome = (): void => {
    props.handleSubmit({
      language: selectedLanguage,
      voice,
      japaneseVoice,
      rate,
      japaneseRate,
    })
    history.replace(CONSTANTS.PATH.HOME);
  }

  return (
    <div
      className={classes.root}
    >
      <div className={classes.languageSelectWrapper}>
        <I18nText text={texts.whichLanguage}/>
        <div className={classes.languageSelect}>
          <div
            data-language={'en'}
            className={clsx(
              classes.language,
              selectedLanguage !== 'ja' && classes.selectedLanguage
            )}
            onClick={onClickLanguage}
          >
            <I18nText text={texts.english}/>
          </div>
          <div
            data-language={'ja'}
            className={clsx(
              classes.language,
              selectedLanguage === 'ja' && classes.selectedLanguage
            )}
            onClick={onClickLanguage}
          >
            <I18nText text={texts.japanese}/>
          </div>
        </div>
      </div>

      {selectedLanguage !== 'ja' &&
      <div className={classes.english}>
        <p
          className={classes.title}
        ><I18nText text={texts.voice}/></p>
        <select
          defaultValue={voice}
          key={voice}
          className={classes.voices}
          onInput={onChangeVoice}
        >
          {props.voices.map(p => <option key={p.name}
                                         value={p.name}
          >{p.name}</option>)}
        </select>
        <p
          className={classes.title}
        ><I18nText text={texts.speed}/></p>
        <div className={classes.rateWrapper}>
          <span><I18nText text={texts.slow}/></span>
          <input type={'range'}
                 min={0.7}
                 max={1.5}
                 key={rate}
                 step={0.1}
                 defaultValue={rate}
                 className={classes.rateRange}
                 onChange={onChangeRate}
          />
          <span><I18nText text={texts.fast}/></span>
        </div>

        <div className={classes.buttonWrapper}
             onClick={speech}>
          <IconButton
            color="inherit"
            aria-label="speech"
          >
            <SpeechIcon
              className={classes.menuButton}
            />
          </IconButton>
          <Typography variant="caption" className={classes.buttonCaption}><I18nText text={texts.speech}/></Typography>
        </div>
      </div>
      }

      {selectedLanguage === 'ja' &&
      <div className={clsx(
        classes.japanese,
      )}>
        <p
          className={classes.title}
        ><I18nText text={texts.voice}/></p>
        <select
          defaultValue={voice}
          key={voice}
          className={classes.voices}
          onInput={onChangeJapaneseVoice}
        >
          {props.japaneseVoices.map(p => <option key={p.name}
                                                 value={p.name}
          >{p.name}</option>)}
        </select>
        <p
          className={classes.title}
        ><I18nText text={texts.speed}/></p>
        <div className={classes.rateWrapper}>
          <span><I18nText text={texts.slow}/></span>
          <input type={'range'}
                 min={0.7}
                 max={1.5}
                 key={rate}
                 step={0.1}
                 defaultValue={japaneseRate}
                 className={classes.rateRange}
                 onChange={onChangeJapaneseRate}
          />
          <span><I18nText text={texts.fast}/></span>
        </div>

        <div className={classes.buttonWrapper}
             onClick={speechJapanese}>
          <IconButton
            color="inherit"
            aria-label="speech"
          >
            <SpeechIcon
              className={classes.menuButton}
            />
          </IconButton>
          <Typography variant="caption" className={classes.buttonCaption}><I18nText text={texts.speech}/></Typography>
        </div>
      </div>
      }

      <div
        className={classes.goToHome}
        onClick={onClickGoToHome}
      >
        <CheckCircleIcon/>
        <span className={classes.goToHomeText}>OK</span>
      </div>
    </div>
  );
};
