export const INIT = 'INIT';
export const SET_MODE = 'SET_MODE';
export const SET_LAST_LAUNCHED_DATE = 'SET_LAST_LAUNCHED_DATE';
export const SET_NAME = 'SET_NAME';
export const SET_VOICE = 'SET_VOICE';
export const SET_DISPLAY_LANGUAGE = 'SET_DISPLAY_LANGUAGE';

export interface SettingState {
  mode: string,
  language: string,
  displayLanguage?: string,
  lastLaunchedDate?: string,
  voice?: string,
  rate?: number,
  japaneseVoice?: string,
  japaneseRate?: number,
  name?: string,
}

interface InitAction {
  type: typeof INIT,
  payload: SettingState,
}

interface SetModeAction {
  type: typeof SET_MODE,
  payload: string,
}

interface SetLastLaunchedDateAction {
  type: typeof SET_LAST_LAUNCHED_DATE,
  payload: string,
}

interface SetNameAction {
  type: typeof SET_NAME,
  payload: string,
}

export interface VoicePayload {
  language: string,
  voice: string,
  japaneseVoice: string,
  rate: number,
  japaneseRate: number,
}

interface SetVoiceAction {
  type: typeof SET_VOICE,
  payload: VoicePayload,
}

interface SetDisplayLanguageAction {
  type: typeof SET_DISPLAY_LANGUAGE,
  payload: string,
}

export type SettingActionTypes = InitAction
  | SetModeAction
  | SetLastLaunchedDateAction
  | SetNameAction
  | SetVoiceAction
  | SetDisplayLanguageAction
