import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import React from 'react';
import {CONSTANTS} from "../constants";

type ProgressBarProps = {
  progress: number,
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      progressBar: {
        marginLeft: 0,
        height: '100%',
        background: CONSTANTS.COLOR.YELLOW,
        width: `${props.progress || 2}%`,
        transition: 'all 200ms 0s ease',
      },
    }),
  );

  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.progressBar}/>
  );
};

export default ProgressBar
