import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React from 'react';
import {Circle} from './LevelProgress';
import {CONSTANTS} from "../constants";
import {I18nText} from "./I18nText";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#000',
    },
    records: {
      margin: 0,
      padding: '16px',
      listStyle: 'none',
      fontSize: '20px',
      fontFamily: 'determination, undertale-ja',
    },
  }),
);

const texts = {
  goalTimes: {
    en: 'goals',
    ja: 'ゴールかいすう',
  },
  studyDays: {
    en: 'study days',
    ja: 'がくしゅうにっすう',
  },
}

type HomeProps = {
  currentLevel: number,
  levelProgress: number,
  totalGoaledCount: number,
  totalStudyDays: number,
}

export const Home: React.FC<HomeProps> = (props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);
  return (
    <div className={classes.root}>
      <Circle
        size={'250'}
        centerText={`LV ${props.currentLevel || 0}`}
        styles={{}}
        progress={props.levelProgress || 0}/>
      <ul className={classes.records}>
        <li><I18nText text={texts.goalTimes}/>: {props.totalGoaledCount}</li>
        <li><I18nText text={texts.studyDays}/>: {props.totalStudyDays}</li>
      </ul>
    </div>
  );
};
