import {LevelActionTypes, LevelState, SET_CURRENT_LEVEL, SET_NEXT_LEVEL} from './types';
import {Level} from "../../entities/level";

const initialState: LevelState = {
  currentLevel: new Level(),
  nextLevel: new Level(),
};

export const reducers = (state = initialState, action: LevelActionTypes): LevelState => {
  switch (action.type) {
    case SET_CURRENT_LEVEL:
      return {
        ...state,
        currentLevel: action.payload,
      };
    case SET_NEXT_LEVEL:
      return {
        ...state,
        nextLevel: action.payload,
      };
    default:
      return state;
  }
};
