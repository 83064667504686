import {HistoryActionTypes, HistoryStore, INCREMENT_TRIED_COUNT, SET_TRIED_COUNT} from './types';

const initialState: HistoryStore = {
  triedCount: 0,
};

export const reducers = (state = initialState, action: HistoryActionTypes): HistoryStore => {
  switch (action.type) {
    case SET_TRIED_COUNT:
      return {
        ...state,
        triedCount: action.payload,
      };
    case INCREMENT_TRIED_COUNT:
      return {
        ...state,
        triedCount: state.triedCount + 1,
      };
    default:
      return state;
  }
};
