import {LevelActionTypes, SET_CURRENT_LEVEL, SET_NEXT_LEVEL} from './types';
import {Level as LevelEntity} from "../../entities/level";

export const setCurrentLevel = (payload: LevelEntity): LevelActionTypes  => ({
  type: SET_CURRENT_LEVEL,
  payload,
});

export const setNextLevel = (payload: LevelEntity): LevelActionTypes => ({
  type: SET_NEXT_LEVEL,
  payload,
});
