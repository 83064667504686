import {
  INIT,
  SET_DISPLAY_LANGUAGE,
  SET_LAST_LAUNCHED_DATE,
  SET_MODE,
  SET_NAME,
  SET_VOICE,
  SettingActionTypes,
  SettingState
} from './types';

const initialState: SettingState = {
  mode: 'ja',
  language: 'en',
  lastLaunchedDate: null,
  voice: null,
  rate: 1,
  japaneseVoice: null,
  japaneseRate: 1,
  name: null,
  displayLanguage: window.navigator.languages[0].startsWith('ja') ? 'ja' : 'en'
};

export const reducers = (state = initialState, action: SettingActionTypes): SettingState => {
  switch (action.type) {
    case INIT:
      return {
        ...state,
        ...action.payload,
      };
    case SET_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case SET_LAST_LAUNCHED_DATE:
      return {
        ...state,
        lastLaunchedDate: action.payload,
      };
    case SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case SET_VOICE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_DISPLAY_LANGUAGE:
      return {
        ...state,
        displayLanguage: action.payload,
      };
    default:
      return state;
  }
};
