import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React from 'react';
import {CheckCircleIcon} from "./icons/CheckCircleIcon";
import useReactRouter from "use-react-router";
import {CONSTANTS} from "../constants";
import {I18nText} from "./I18nText";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      color: CONSTANTS.COLOR.WHITE,
      fontFamily: 'determination, undertale-ja',
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
      justifyContent: 'space-between',
    },
    title: {
      fontSize: '20px',
    },
    languages: {
      color: CONSTANTS.COLOR.WHITE,
      width: '100%',
      padding: 8,
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
      fontSize: '1.6rem',
      fontFamily: 'determination, undertale-ja',
      marginBottom: '20px',
      background: '#000',
    },
    goToHome: {
      color: '#fff',
      padding: '20px 60px',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'determination, undertale-ja',
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`
    },
    goToHomeText: {
      marginLeft: '8px',
    },
  }),
);
const texts = {
  displayLanguage: {
    en: 'language',
    ja: 'げんごせってい',
  },
}

type LanguageProps = {
  displayLanguage: string | null,
  onLanguageChanged: Function,
}

export const Language: React.FC<LanguageProps> = (props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);
  const {history} = useReactRouter();
  const {displayLanguage, onLanguageChanged} = props

  const onClickGoToHome = (): void => {
    history.replace(CONSTANTS.PATH.HOME);
  }

  return (
    <div
      className={classes.root}
    >
      <p
        className={classes.title}
      ><I18nText text={texts.displayLanguage}/></p>
      <select value={displayLanguage}
              className={classes.languages}
              onChange={(e) => onLanguageChanged(e.currentTarget.value)}>
        <option value={'ja'}>にほんご</option>
        <option value={'en'}>English</option>
      </select>

      <div
        className={classes.goToHome}
        onClick={onClickGoToHome}
      >
        <CheckCircleIcon/>
        <span className={classes.goToHomeText}>OK</span>
      </div>
    </div>
  )
}
