import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React from 'react';
import {useSelector} from "react-redux";
import {RootState} from "../rootReducer";

export type I18nText = {
  ja: string,
  en: string,
}
type I18nTextProps = {
  text: I18nText,
}

export const I18nText: React.FC<I18nTextProps> = (props) => {
  const settingStore = useSelector((state: RootState) => state.settings);
  const {
    text,
  } = props

  return (
    <span>{settingStore.displayLanguage === 'ja' ? text.ja : text.en}</span>
  );
};
