import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import React from 'react';
import {NavLink} from 'react-router-dom';
import {EnglishIcon} from "./icons/EnglishIcon";
import {JapaneseIcon} from "./icons/JapaneseIcon";
import {SpeechIcon} from "./icons/SpeechIcon";
import {CONSTANTS} from "../constants";
import {NameIcon} from "./icons/NameIcon";
import {i18nUtil} from "../utils/i18n";
import {LanguageIcon} from "./icons/LanguageIcon";

const useStyles = makeStyles((theme) =>
  createStyles({
    listWrapper: {
      width: 280,
      height: '100%',
      color: CONSTANTS.COLOR.WHITE,
      background: '#000',
      padding: 4,
    },
    list: {
      height: '100%',
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
      color: CONSTANTS.COLOR.WHITE,
      background: '#000',
    },
    navLink: {
      color: CONSTANTS.COLOR.WHITE,
      textDecoration: 'none',
    },
    menuText: {
      fontSize: '0.8rem',
      fontFamily: 'determination, undertale-ja',
      textDecoration: 'none',
    },
    subMenu: {
      color: CONSTANTS.COLOR.WHITE,
      textDecoration: 'none',
    },
    pngIcon: {
      width: '1.6rem',
      height: '1.6rem',
    },
  }),
);

const texts = {
  hideJapanese: {
    en: 'hide Japanese',
    ja: '英語 -> 日本語で学習する',
  },
  hideEnglish: {
    en: 'hide English',
    ja: '日本語 -> 英語で学習する',
  },
  voice: {
    en: 'voice pattern',
    ja: '発音パターン',
  },
  name: {
    en: 'name',
    ja: '名前をつける',
  },
  displayLanguage: {
    en: 'site language',
    ja: 'げんごせってい',
  },
}

type SideListProps = {
  toggleDrawer: Function
  displayLanguage: string,
}

export const SideList: React.FC<SideListProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {toggleDrawer, displayLanguage} = props

  return (
    <div
      className={classes.listWrapper}
    >
      <div
        className={classes.list}
        role="presentation"
        onClick={toggleDrawer('left', false)}
        onKeyDown={toggleDrawer('left', false)}
      >
        <List>
          <NavLink to={'/en'} className={classes.navLink} exact>
            <ListItem button key={1}>
              <ListItemIcon>
                <EnglishIcon
                  color={CONSTANTS.COLOR.WHITE}
                />
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.menuText}}
                primary={i18nUtil.choose(texts.hideJapanese, displayLanguage)}/>
            </ListItem>
          </NavLink>
          <NavLink to={'/ja'} className={classes.navLink} exact>
            <ListItem button key={2}>
              <ListItemIcon>
                <JapaneseIcon
                  color={CONSTANTS.COLOR.WHITE}
                />
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.menuText}}
                primary={i18nUtil.choose(texts.hideEnglish, displayLanguage)}/>
            </ListItem>
          </NavLink>
          <NavLink to={'/voices'} className={classes.navLink} exact>
            <ListItem button key={3}>
              <ListItemIcon>
                <SpeechIcon
                  color={CONSTANTS.COLOR.WHITE}
                />
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.menuText}}
                primary={i18nUtil.choose(texts.voice, displayLanguage)}/>
            </ListItem>
          </NavLink>
          <NavLink to={'/name'} className={classes.navLink} exact>
            <ListItem button key={4}>
              <ListItemIcon>
                <NameIcon
                  color={CONSTANTS.COLOR.WHITE}
                />
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.menuText}}
                primary={i18nUtil.choose(texts.name, displayLanguage)}/>
            </ListItem>
          </NavLink>
          <NavLink to={'/language'} className={classes.navLink} exact>
            <ListItem button key={4}>
              <ListItemIcon>
                <LanguageIcon
                  color={CONSTANTS.COLOR.WHITE}
                />
              </ListItemIcon>
              <ListItemText
                classes={{primary: classes.menuText}}
                primary={i18nUtil.choose(texts.displayLanguage, displayLanguage)}/>
            </ListItem>
          </NavLink>
        </List>
      </div>
    </div>
  );
};
