import {
  INIT,
  SET_DISPLAY_LANGUAGE,
  SET_LAST_LAUNCHED_DATE,
  SET_MODE,
  SET_NAME,
  SET_VOICE,
  SettingActionTypes,
  SettingState,
  VoicePayload
} from "./types";

export const init = (payload: SettingState): SettingActionTypes => ({
  type: INIT,
  payload
});
export const setMode = (payload: string): SettingActionTypes => ({
  type: SET_MODE,
  payload
});
export const setLastLaunchedDate = (payload: string): SettingActionTypes => ({
  type: SET_LAST_LAUNCHED_DATE,
  payload
});
export const setName = (payload: string): SettingActionTypes => ({
  type: SET_NAME,
  payload
});
export const setVoice = (payload: VoicePayload): SettingActionTypes => ({
  type: SET_VOICE,
  payload
});
export const setDisplayLanguage = (payload: string): SettingActionTypes => ({
  type: SET_DISPLAY_LANGUAGE,
  payload
});
