export class Word {
  wordId: string;
  word: string;
  mean: string;
  character: string;

  words(): string[] {
    return this.word.split('\n');
  }

  means(): string[] {
    return this.mean.split('\n');
  }

  isPapyrus(): boolean {
    return this.character === 'p'
  }

  isSans(): boolean {
    return this.character === 's'
  }
}
