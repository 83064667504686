import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import React from 'react';
import {CheckCircleIcon} from "./icons/CheckCircleIcon";
import {CONSTANTS} from "../constants";
import {BackspaceIcon} from "./icons/BackspaceIcon";
import {I18nText} from "./I18nText";
import {i18nUtil} from "../utils/i18n";

const useStyles = makeStyles((theme) =>
  createStyles({
    '@keyframes vibe': {
      '0%': {transform: 'translate(0px, 0px) rotateZ(0deg)'},
      '25%': {transform: 'translate(2px, 2px) rotateZ(1deg)'},
      '50%': {transform: 'translate(0px, 2px) rotateZ(0deg)'},
      '75%': {transform: 'translate(2px, 0px) rotateZ(-1deg)'},
      '100%': {transform: 'translate(0px, 0px) rotateZ(0deg)'},
    },
    root: {
      color: CONSTANTS.COLOR.WHITE,
      fontFamily: 'determination, undertale-ja',
      display: 'flex',
      flexDirection: 'column',
      padding: 16,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    input: {
      width: '100%',
    },
    confirm: {
      width: '100%',
    },
    title: {
      fontSize: '20px',
      textAlign: 'center',
    },
    name: {
      color: CONSTANTS.COLOR.WHITE,
      fontFamily: 'determination, undertale-ja',
      margin: 20,
      height: 40,
      fontSize: '1.6rem',
      textAlign: 'center',
    },
    confirmNameVibe: {
      animation: `$vibe .1s  infinite`,
    },
    confirmName: {
      color: CONSTANTS.COLOR.WHITE,
      fontFamily: 'determination, undertale-ja',
      margin: 20,
      height: 40,
      fontSize: '3rem',
      textAlign: 'center',
    },
    confirmButtons: {
      display: 'flex',
      width: '100%'
    },
    alphabetWrapper: {
      '&::after': {
        content: '""',
        flex: 'auto',
      },
      display: 'flex',
      flexWrap: 'wrap',
      fontFamily: 'determination',
      fontSize: '2rem',
      justifyContent: 'space-between',
    },
    alphabet: {
      padding: '5px 10px',
      animation: `$vibe .1s  infinite`,
    },
    goToHome: {
      marginTop: 50,
      color: '#fff',
      padding: '20px 60px',
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'determination',
      border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
      flexGrow: 1,
      height: 30,
    },
    goToHomeText: {
      marginLeft: '8px',
    },
    acceptWrapper: {
      marginLeft: 8,
    }
  }),
);

const texts = {
  name: {
    en: 'Name the fallen human.',
    ja: 'おちたニンゲンに なまえをつけてください',
  },
  confirm: {
    en: 'Is this name correct?',
    ja: 'このなまえで　よろしいですか？',
  },
}

type NameProps = {
  displayLanguage: string,
  name: string,
  handleInput: (char: string) => void,
  handleBackspace: () => void,
  handleConfirm: () => void,
  handleAccept: () => void,
  handleBack: () => void,
  specialNameText: string,
  isForbidden: boolean,
  isConfirm: boolean,
}

export const Name: React.FC<NameProps> = (props) => {
  const theme: Theme = useTheme();
  const classes = useStyles(theme);
  const {
    displayLanguage,
    name,
    handleInput,
    handleBackspace,
    handleConfirm,
    handleAccept,
    handleBack,
    specialNameText,
    isForbidden,
    isConfirm,
  } = props

  const alphabetUpper = (): string[] => {
    return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
  }

  const alphabetLower = (): string[] => {
    return 'abcdefghijklmnopqrstuvwxyz'.split('')
  }

  const alphabetComponent = (char: string): JSX.Element => {
    return (
      <span
        key={char}
        className={classes.alphabet}
        onClick={() => {
          handleInput(char)
        }}>{char}</span>
    );
  }

  return (
    <div
      className={classes.root}
    >
      {!isConfirm && <div className={classes.input}>
        <p
          className={classes.title}
        ><I18nText text={texts.name}/></p>

        <p
          className={classes.name}
        >{name}</p>

        <div
          className={classes.alphabetWrapper}
        >
          {alphabetUpper().map(p => alphabetComponent(p))}
        </div>
        <div
          className={classes.alphabetWrapper}
        >
          {alphabetLower().map(p => alphabetComponent(p))}
          <span
            className={classes.alphabet}
            onClick={() => handleBackspace()}>
          <BackspaceIcon/>
        </span>
        </div>

        <div
          className={classes.goToHome}
          onClick={() => handleConfirm()}
        >
          <CheckCircleIcon/>
          <span className={classes.goToHomeText}>OK</span>
        </div>
      </div>
      }

      {isConfirm && <div className={classes.confirm}>
        <p
          className={classes.title}>{specialNameText || i18nUtil.choose(texts.confirm, displayLanguage)}</p>

        <div
          className={classes.confirmNameVibe}>
          <p className={classes.confirmName}>{name}</p>
        </div>

        <div
          className={classes.confirmButtons}
        >
          <div
            className={classes.goToHome}
            onClick={() => handleBack()}
          >
            <span className={classes.goToHomeText}>Back</span>
          </div>

          {!isForbidden && <div className={classes.acceptWrapper}>
            <div
              className={classes.goToHome}
              onClick={() => handleAccept()}
            >
              <CheckCircleIcon/>
              <span className={classes.goToHomeText}>OK</span>
            </div>
          </div>
          }
        </div>
      </div>
      }
    </div>
  );
};
