import {
  HIDE_ANSWER, SET_MEAN,
  SET_QUESTIONS,
  SET_WORD,
  SHOW_ANSWER,
  Speech,
  SPEECH,
  WordActionTypes
} from "./types";
import {Word as WordEntity} from '../../entities/word'

export const showAnswer = (): WordActionTypes => ({
  type: SHOW_ANSWER,
});

export const hideAnswer = (): WordActionTypes => ({
  type: HIDE_ANSWER,
});

export const setWord = (payload: string): WordActionTypes => {
  return {
    type: SET_WORD,
    payload: payload,
  };
};

export const setMean = (payload: string): WordActionTypes => {
  return {
    type: SET_MEAN,
    payload: payload,
  };
};

export const setQuestions = (payload: WordEntity[]): WordActionTypes => {
  return {
    type: SET_QUESTIONS,
    payload: payload,
  };
};

export const speech = (payload: Speech): WordActionTypes => {
  const synth = window.speechSynthesis;
  const utterThis: SpeechSynthesisUtterance = new SpeechSynthesisUtterance(payload.text);
  const voices = speechSynthesis.getVoices()
  const selectedVoice: SpeechSynthesisVoice = voices.find(p => p.name === payload.voice);
  utterThis.voice = selectedVoice || voices.find(p => p.lang.startsWith(payload.language || 'en'))
  utterThis.lang = selectedVoice?.lang || utterThis.voice?.lang || (payload.language === 'ja' ? 'ja-JP' : 'en-US')
  utterThis.rate = payload.rate || 1

  synth.cancel();
  synth.speak(utterThis);

  return {
    type: SPEECH,
    payload: null
  };
};
