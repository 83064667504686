import AppBar from '@material-ui/core/AppBar';
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import {SpeechIcon} from "./icons/SpeechIcon";
import {AnswerIcon} from "./icons/AnswerIcon";
import {CONSTANTS} from "../constants";

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      position: 'inherit',
      background: '#000',
    },
    toolbar: {
      paddingRight: 8,
      paddingLeft: 8,
    },
    homeButtons: {
      flexGrow: 3,
      display: 'flex',
      justifyContent: 'center',
    },
    controlButtons: {
      flexGrow: 3,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonWrapper: {
      color: CONSTANTS.COLOR.ORANGE,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: `4px solid ${CONSTANTS.COLOR.ORANGE}`,
    },
    menuButton: {
      flexGrow: 1,
    },
    listen: {
      border: '4px solid #FF893C',
      alignItems: 'center',
      background: 'url(/images/commands/listen-orange.png)',
      backgroundPositionY: 'center',
      backgroundSize: 'contain',
      backgroundPositionX: 'right',
      backgroundRepeat: 'no-repeat',
      paddingRight: 70,
      marginLeft: 8,
    },
    answer: {
      border: '4px solid #FF893C',
      alignItems: 'center',
      background: 'url(/images/commands/answer-orange.png)',
      backgroundPositionY: 'center',
      backgroundSize: 'contain',
      backgroundPositionX: 'right',
      backgroundRepeat: 'no-repeat',
      paddingRight: 70,
      marginLeft: 8,
    },
    menu: {
      border: '4px solid #FF893C',
      alignItems: 'center',
      background: 'url(/images/commands/menu-orange.png)',
      backgroundPositionY: 'center',
      backgroundSize: 'contain',
      backgroundPositionX: 'right',
      backgroundRepeat: 'no-repeat',
      paddingRight: 50,
    },
    start: {
      border: '4px solid #FF893C',
      alignItems: 'center',
      background: 'url(/images/commands/start-orange.png)',
      backgroundPositionY: 'center',
      backgroundSize: 'contain',
      backgroundPositionX: 'right',
      backgroundRepeat: 'no-repeat',
      paddingRight: 67,
    },
    soul: {
      width: 20,
      height: 20,
      marginTop: 2,
      marginBottom: 2,
    },
  }),
);

type ToolBarProps = {
  toggleDrawer: Function,
  position: string
  startStudy: () => void,
  showAnswer: () => void,
  speechText: () => void,
  isHome: boolean,
}

export const ToolBar: React.FC<ToolBarProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <AppBar
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <div
          onClick={() => {
            props.toggleDrawer('left', true)();
          }}
          className={clsx(classes.buttonWrapper, classes.menu)}>
          <MenuIcon
            className={classes.menuButton}
          />
        </div>
        {props.isHome ?
          <div className={classes.homeButtons}>
            <div
              onClick={props.startStudy}
              className={clsx(classes.buttonWrapper, classes.start)}>
              <img src={'/images/soul.png'} className={classes.soul}/>
            </div>
          </div>
          :
          <div className={classes.controlButtons}>
            <div
              onClick={props.showAnswer}
              className={clsx(classes.buttonWrapper, classes.answer)}>
              <AnswerIcon
                className={classes.menuButton}
              />
            </div>
            <div
              onClick={props.speechText}
              className={clsx(classes.buttonWrapper, classes.listen)}>
              <SpeechIcon
                className={classes.menuButton}
              />
            </div>
          </div>
        }
      </Toolbar>
    </AppBar>
  );
};
