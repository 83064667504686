import {Level as LevelEntity} from "../../entities/level";

export const SET_CURRENT_LEVEL = 'SET_CURRENT_LEVEL';
export const SET_NEXT_LEVEL = 'SET_NEXT_LEVEL';

export interface LevelState {
  currentLevel: LevelEntity,
  nextLevel: LevelEntity,
}

interface SetCurrentLevelAction {
  type: typeof SET_CURRENT_LEVEL;
  payload: LevelEntity
}

interface SetNextLevelAction {
  type: typeof SET_NEXT_LEVEL;
  payload: LevelEntity
}

export type LevelActionTypes = SetCurrentLevelAction | SetNextLevelAction
