import React from 'react';
import db from "../db";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../rootReducer";
import {setDisplayLanguage} from "../modules/setting/actions";
import {Language} from "../components/Language";

const LanguageContainer: React.FC = () => {
  const settingStore = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();

  const acceptDisplayLanguage = (displayLanguage: string) => {
    (async () => {
      dispatch(setDisplayLanguage(displayLanguage))
      await db.settings.update(1, {
        displayLanguage
      });
    })();
  };

  return (
    <Language displayLanguage={settingStore.displayLanguage}
              onLanguageChanged={acceptDisplayLanguage}
    />
  );
};

export default LanguageContainer
