import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import {bindKeyboard, virtualize} from 'react-swipeable-views-utils';
import {createStyles, makeStyles, useTheme} from "@material-ui/core/styles";
import {SlideRendererProps} from "./SlideRenderer";

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

export type OnChangeIndex = (index: number, indexLatest: number) => void

type ViewPagerProps = {
  index: number,
  enableMouseEvents: boolean,
  onChangeIndex: OnChangeIndex,
  slideRenderer: React.FC<SlideRendererProps>,
  slideCount: number,
  overscanSlideAfter: number,
  overscanSlideBefore: number,
}

const ViewPager: React.FC<ViewPagerProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {
    index,
    onChangeIndex,
    slideRenderer,
    slideCount,
  } = props;
  return (
    <VirtualizeSwipeableViews
      className={classes.root}
      index={index}
      ignoreNativeScroll={true}
      enableMouseEvents={true}
      onChangeIndex={onChangeIndex}
      slideRenderer={slideRenderer}
      slideCount={slideCount}
      style={{height: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column'}}
      containerStyle={{height: '100%', flexGrow: 1, display: 'flex'}}
      overscanSlideAfter={1}
      overscanSlideBefore={1}
    />
  );
};

export default ViewPager
