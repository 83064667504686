import makeStyles from '@material-ui/core/styles/makeStyles';
import confetti from 'canvas-confetti';
import dayjs from 'dayjs';
import React, {FunctionComponent, useEffect, useState} from 'react';
import useReactRouter from 'use-react-router';
import GoalSlideRenderer from '../components/GoalSlideRenderer';
import {Circle} from '../components/LevelProgress';
import ProgressBar from '../components/ProgressBar';
import SlideRenderer from '../components/SlideRenderer';
import ViewPager, {OnChangeIndex} from '../components/ViewPager';
import {CONSTANTS} from '../constants';
import db from '../db';
import {Level} from "../entities/level";
import {Word} from "../entities/word";
import {useDispatch, useSelector} from "react-redux";
import {setMode} from "../modules/setting/actions";
import {hideAnswer, setMean, setWord} from "../modules/word/actions";
import {incrementTriedCount} from "../modules/history/actions";
import {setCurrentLevel, setNextLevel} from "../modules/level/actions";
import {RootState} from "../rootReducer";
import {I18nText} from "../components/I18nText";

const useStyles = makeStyles({
  root: {
    color: CONSTANTS.COLOR.WHITE,
    position: 'relative',
    height: '100%',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'mars',
    fontSize: '1rem',
    marginTop: 12,
    marginLeft: 8,
    marginRight: 8,
  },
  progressWrapper: {
    width: 120,
    height: 24,
    background: CONSTANTS.COLOR.DAMAGED_RED,
    marginLeft: 8,
    marginRight: 8,
  },
  attention: {
    fontFamily: 'determination, undertale-ja',
    marginRight: 16,
    alignSelf: 'flex-end',
  }
});

const texts = {
  swipeAndGo: {
    en: 'swipe and go next',
    ja: 'スワイプして　つぎの　もんだいへ',
  },
}

const ViewPagerContainer: React.FC = () => {
  const classes = useStyles({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isGoaled, setGoaled] = useState(false);
  const [isBack, setBack] = useState(false);
  const [temmie, setTemmie] = useState([]);
  const [lesserDogUp, setLesserDogUp] = useState(false);
  const [lesserDogDown, setLesserDogDown] = useState(false);
  const [hangedDog, setHangedDog] = useState(false);
  const [walkDog, setWalkDog] = useState(false);
  const {match: {params}, history} = useReactRouter();
  const dispatch = useDispatch();
  const wordStore = useSelector((state: RootState) => state.words);
  const levelStore = useSelector((state: RootState) => state.levels);
  const historyStore = useSelector((state: RootState) => state.histories);
  const settingStore = useSelector((state: RootState) => state.settings);

  useEffect(() => {
    (async () => {
      await db.settings.update(1, {
        mode: params.language,
      });
      dispatch(setMode(params.language))
    })();
    return () => {
    }
  }, [params.language]); // homeを開くたびに問題を更新する

  const clearTemmie = () => {
    setTemmie([])
  }

  const clearLesserDog = () => {
    setLesserDogUp(false)
    setLesserDogDown(false)
  }

  const clearHangedDog = () => {
    setHangedDog(false)
  }

  const clearWalkDog = () => {
    setWalkDog(false)
  }

  const onChangeIndex: OnChangeIndex = async (index, indexLatest) => {
    if (!isGoaled && index === wordStore.questions.length) {
      await goaled();
      shotConfetti();
    }
    setBack(index < indexLatest)
    dispatch(setMode(params.language))
    dispatch(hideAnswer())
    setCurrentIndex(index);
    const currentProgress = index / wordStore.questions.length * 100;
    if (progress < currentProgress) {
      // TODO: todayをdryにしたい
      const today: string = dayjs().format(CONSTANTS.DATE.FORMAT.YMD);
      await db.histories.where({date: today})
      .modify(row => {
        row.triedCount++;
      });
      dispatch(incrementTriedCount())
      setProgress(currentProgress);
    }
    const currentWord = wordStore.questions[index];
    if (currentWord) {
      dispatch(setWord(currentWord.word.replace('#name#', settingStore.name)))
      dispatch(setMean(currentWord.mean.replace('#name#', settingStore.name)))
    }

    clearTemmie()
    clearLesserDog()
    clearHangedDog()
    clearWalkDog()
  };

  const goaled = async (): Promise<void> => {
    setGoaled(true);
    const today: string = dayjs().format(CONSTANTS.DATE.FORMAT.YMD);
    return await db.histories.where({date: today})
    .modify(row => {
      row.goaledCount++;
    });
  };

  const needsWordShow = (index: number): boolean => {
    if (params.language === CONSTANTS.SETTING.MODE.EN) {
      return true;
    }
    return wordStore.isShow && currentIndex === index;
  };

  const needsMeansShow = (index: number): boolean => {
    if (params.language === CONSTANTS.SETTING.MODE.JA) {
      return true;
    }
    return wordStore.isShow && currentIndex === index;
  };

  const goToHome = (event: React.MouseEvent<HTMLInputElement>): void => {
    history.replace('/home');
  };

  const calculateProgress = (): number => {
    const needExperience = levelStore.nextLevel.experience -
      levelStore.currentLevel.experience;
    const gotExperience = historyStore.triedCount -
      levelStore.currentLevel.experience;
    const progress = gotExperience / needExperience * 100;
    if (progress === 100) {
      shotConfetti();
    }
    return progress || 0;
  };

  const onCircleTransitionEnd = () => {
    (async () => {
      const currentLevel: Level = await db.levels.where('experience')
      .belowOrEqual(historyStore.triedCount)
      .reverse()
      .first();
      const nextLevel: Level = await db.levels.where('experience')
      .above(historyStore.triedCount)
      .first();
      dispatch(setCurrentLevel(currentLevel))
      dispatch(setNextLevel(nextLevel))
    })();
  };

  const shotConfetti = () => {
    const random = (min: number, max: number): number => {
      return Math.random() * (max - min) + min;
    };

    const shotLimit: number = 3;
    let shotTimes: number = 0;
    const interval = setInterval(() => {
        confetti({
          angle: random(55, 125),
          spread: random(50, 70),
          particleCount: random(50, 100),
          gravity: 2,
          origin: {
            y: 1.3,
          },
        });
        shotTimes++;
        if (shotTimes >= shotLimit) {
          clearInterval(interval);
        }
      },
      300,
    );
  };

  type SlideRendererProps = {
    index: number,
    key: string,
  }

  const slideRenderer: React.FC<SlideRendererProps> = (params) => {
    const {index, key} = params;

    const word: Word = wordStore.questions[index];
    if (!word) {
      return <GoalSlideRenderer
        key={key}
        temmie={temmie}
        setTemmie={setTemmie}
        lesserDogUp={lesserDogUp}
        setLesserDogUp={setLesserDogUp}
        lesserDogDown={lesserDogDown}
        setLesserDogDown={setLesserDogDown}
        hangedDog={hangedDog}
        setHangedDog={setHangedDog}
        walkDog={walkDog}
        setWalkDog={setWalkDog}
        level={levelStore.currentLevel.level}
        onPrimaryButtonClick={goToHome}
        displayLanguage={settingStore.displayLanguage}
      />;
    }
    return <SlideRenderer
      key={key}
      word={word}
      needsWordShow={needsWordShow(index)}
      needsMeansShow={needsMeansShow(index)}
      name={settingStore.name}
      index={index}
      isBack={isBack}
    />;
  };

  return (
    <div
      className={classes.root}
    >
      <ViewPager
        index={currentIndex}
        enableMouseEvents={true}
        onChangeIndex={onChangeIndex}
        slideRenderer={slideRenderer}
        slideCount={CONSTANTS.QUESTION.COUNT + 1}
        overscanSlideAfter={1}
        overscanSlideBefore={1}
      />
      {levelStore.currentLevel.level === 1 &&
      <p className={classes.attention}><I18nText text={texts.swipeAndGo}/>→ </p>
      }
      <div
        className={classes.status}
      >
        <span>{settingStore.name}</span>
        <Circle
          size={'50'}
          centerText={`LV ${levelStore.currentLevel.level || 0}`}
          styles={{
            marginRight: 8,
            marginLeft: 8,
          }}
          onAnimationEnd={onCircleTransitionEnd}
          progress={calculateProgress()}/>
        <span>PROGRESS</span>
        <div
          className={classes.progressWrapper}
        >
          <ProgressBar
            progress={progress}/>
        </div>
        <span>{Math.min(currentIndex + 1, wordStore.questions.length)}/{wordStore.questions.length}</span>
      </div>
    </div>
  );
};

export default ViewPagerContainer
