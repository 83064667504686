export const arrayUtil = {
  shuffle: ([...arr]: any[]): any[] => {
    let m: number = arr.length;
    while (m) {
      const i: number = Math.floor(Math.random() * m--);
      [arr[m], arr[i]] = [arr[i], arr[m]];
    }
    return arr;
  },
};
