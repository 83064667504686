export const SET_TRIED_COUNT = 'SET_TRIED_COUNT';
export const INCREMENT_TRIED_COUNT = 'INCREMENT_TRIED_COUNT';

export interface HistoryStore {
  triedCount: number
}

interface SetTriedCountAction {
  type: typeof SET_TRIED_COUNT;
  payload: number;
}

interface SetIncrementTriedCount {
  type: typeof INCREMENT_TRIED_COUNT;
}

export type HistoryActionTypes = SetTriedCountAction | SetIncrementTriedCount
