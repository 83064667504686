import {Word as WordEntity} from '../../entities/word'

export const SHOW_ANSWER = 'SHOW_ANSWER';
export const HIDE_ANSWER = 'HIDE_ANSWER';
export const SET_WORD = 'SET_WORD';
export const SET_MEAN = 'SET_MEAN';
export const SPEECH = 'SPEECH';
export const SET_QUESTIONS = 'SET_QUESTIONS';

export interface WordState {
  isShow: boolean;
  text: string;
  mean: string;
  questions: WordEntity[];
}

export interface Speech {
  text: string;
  voice?: string;
  rate?: number;
  language?: string;
}

interface ShowAnswerAction {
  type: typeof SHOW_ANSWER;
}

interface HideAnswerAction {
  type: typeof HIDE_ANSWER;
}

interface SetWordAction {
  type: typeof SET_WORD;
  payload: string
}

interface SetMeanAction {
  type: typeof SET_MEAN;
  payload: string
}

interface SetQuestionsAction {
  type: typeof SET_QUESTIONS;
  payload: WordEntity[];
}

interface SpeechAction {
  type: typeof SPEECH;
  payload: Speech
}

export type WordActionTypes =
  ShowAnswerAction
  | HideAnswerAction
  | SetWordAction
  | SetMeanAction
  | SetQuestionsAction
  | SpeechAction
