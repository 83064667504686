import {HistoryActionTypes, INCREMENT_TRIED_COUNT, SET_TRIED_COUNT} from './types';

export const setTriedCount = (payload: number): HistoryActionTypes => ({
  type: SET_TRIED_COUNT,
  payload: payload,
});

export const incrementTriedCount = (): HistoryActionTypes => ({
  type: INCREMENT_TRIED_COUNT,
});
