import {
  HIDE_ANSWER,
  SET_MEAN,
  SET_QUESTIONS,
  SET_WORD,
  SHOW_ANSWER,
  WordActionTypes,
  WordState
} from './types';

const initialState: WordState = {
  isShow: false,
  text: 'init',
  mean: '',
  questions: [],
};

export const reducers = (state = initialState, action: WordActionTypes): WordState => {
  switch (action.type) {
    case SHOW_ANSWER:
      return {
        ...state,
        isShow: true,
      };
    case HIDE_ANSWER:
      return {
        ...state,
        isShow: false,
      };
    case SET_WORD:
      return {
        ...state,
        text: action.payload,
      };
    case SET_MEAN:
      return {
        ...state,
        mean: action.payload,
      };
    case SET_QUESTIONS:
      return {
        ...state,
        questions: action.payload,
      };
    default:
      return state;
  }
};
