import dayjs from 'dayjs';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Init from './components/Init';
import {CONSTANTS} from './constants';
import HomeContainer from './containers/HomeContainer';
import StudyContainer from './containers/StudyContainer';
import ViewPagerContainer from './containers/ViewPagerContainer';
import LandingContainer from "./containers/LandingContainer";
import VoiceContainer from "./containers/VoiceContainer";
import {License} from "./components/License";
import NameContainer from "./containers/NameContainer";
import {SettingState} from "./modules/setting/types";
import {useSelector} from "react-redux";
import {RootState} from "./rootReducer";
import LanguageContainer from "./containers/LanguageContainer";

const App = () => {

  const settingStore: SettingState = useSelector((state: RootState) => state.settings);

  const isReady = (): boolean => {
    const today: string = dayjs().format(CONSTANTS.DATE.FORMAT.YMD);
    // TODO store.settings.isTodayLaunched() と訊きたい
    return settingStore.lastLaunchedDate === today;
  };

  if (!isReady()) {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={LandingContainer}/>
          <Route exact path="*" component={Init}/>
        </Switch>
      </Router>
    );
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LandingContainer}/>
        <Route exact path="/language" component={LanguageContainer}/>
        <Route exact path="/name" component={NameContainer}/>
        <Route exact path="/voices" component={VoiceContainer}/>
        <Route exact path="/license" component={License}/>
        <StudyContainer>
          <Route exact path="/home" component={HomeContainer}/>
          <Route exact path="/:language(en|ja)" component={ViewPagerContainer}/>
        </StudyContainer>
      </Switch>
    </Router>
  );
};

export default App
