import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {CONSTANTS} from '../constants';
import {Word as WordEntity} from '../entities/word'

const useStyles = makeStyles({
  '@keyframes diveAndHide': {
    '0%': {bottom: 0},
    '100%': {bottom: -120},
  },
  wordWrapper: {
    fontSize: 18,
    fontFamily: 'determination',
    border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
    flexBasis: '50%',
    marginBlockStart: 0,
    marginBlockEnd: 20,
    overflowY: 'scroll',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#aaa',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#000',
    },
    '&::-webkit-scrollbar': {
      width: 6,
    },
  },
  show: {
    display: 'inline-block',
  },
  hide: {
    visibility: 'hidden',
  },
  slide: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
  },
  meansWrapper: {
    flexBasis: '50%',
    border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 8,
    overflowY: 'scroll',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#aaa',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#000',
    },
    '&::-webkit-scrollbar': {
      width: 6,
    },
  },
  words: {
    display: 'flex',
    listStyle: 'none',
    paddingLeft: 4,
    paddingRight: 4,
    flexWrap: 'wrap',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingTop: 8,
    flexDirection: 'column',
  },
  word: {
    color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
    marginRight: 4,
    marginLeft: 4,
  },
  means: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    flexWrap: 'wrap',
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingTop: 8,
    flexDirection: 'column',
    fontFamily: 'undertale-ja',
    color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
  },
  sans: {
    fontFamily: 'sans-ja',
  },
  papyrus: {
    writingMode: 'vertical-rl',
    fontFamily: 'papyrus-ja',
    textOrientation: 'upright',

    '& li': {
      marginBottom: 30,
      marginLeft: 4,
    },
  },
  sansEn: {
    fontFamily: 'sans-en',
  },
  papyrusEn: {
    fontFamily: 'papyrus-en',
    fontWeight: 900,
    fontSize: '1rem',
    lineHeight: '1.4rem',
    letterSpacing: 2,
    wordSpacing: '0.5rem'
  },
  mean: {
    marginRight: 8,
    marginLeft: 8,
    fontSize: 18,
  },
  flowey: {
    height: 60,
    width: 60,
    position: 'absolute',
    bottom: 0,
    zIndex: -1,
  },
  floweyHide: {
    animation: `$diveAndHide 1000ms`,
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
  }
});

export type SlideRendererProps = {
  key: string,
  word: WordEntity,
  needsWordShow: boolean,
  needsMeansShow: boolean,
  name: string,
  index: number,
  isBack: boolean,
}

const SlideRenderer: React.FC<SlideRendererProps> = (props) => {
  const classes = useStyles();
  const {
    word,
    needsWordShow,
    needsMeansShow,
    name,
    index,
    isBack,
  } = props;

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * Math.floor(max));
  };

  return (
    <div
      className={classes.slide}
    >
      <div
        className={classes.wordWrapper}
      >
        <ul className={clsx(
          needsWordShow ? classes.show : classes.hide,
          word.isPapyrus() ? classes.papyrusEn : '',
          word.isSans() ? classes.sansEn : '',
          classes.words
        )}>
          {word.words().map((word, i) => {
            return <li key={i} className={classes.word}>{word.replace('#name#', name)}</li>;
          })}
        </ul>
      </div>
      <div className={classes.meansWrapper}>
        <ul className={clsx(
          word.isPapyrus() ? classes.papyrus : '',
          word.isSans() ? classes.sans : '',
          needsMeansShow ? classes.show : classes.hide, classes.means)}>
          {word.means().map((mean, i) => {
            return <li key={i} className={classes.mean}>{mean.replace('#name#', name)}</li>;
          })}
        </ul>
      </div>
      {(isBack && index === 0 && getRandomInt(20) % 19 === 0) &&
      <img src={'/images/flowey.png'} className={clsx(
        classes.flowey,
        classes.floweyHide
      )}/>
      }
    </div>
  );
};

export default SlideRenderer;
