import * as React from 'react';
import {Component, CSSProperties} from 'react';
import {CONSTANTS} from "../constants";

export interface CircleProps {
  progress: number;
  centerText: string,
  animate?: boolean;
  animationDuration?: string;
  progressColor?: string;
  bgColor?: string;
  textColor?: string;
  size?: string;
  lineWidth?: string;
  percentSpacing?: number;
  textStyle?: CSSProperties;
  styles?: CSSProperties;
  roundedStroke?: boolean;
  responsive?: boolean;

  onAnimationEnd?(): void;
}

export interface CircleState {

}

const radius = 175;
const diameter = Math.round(Math.PI * radius * 2);
const getOffset = (val = 0) => Math.round((100 - Math.min(val, 100)) / 100 * diameter);

export class Circle extends Component<CircleProps, CircleState> {
  static defaultProps: CircleProps = {
    progress: 0,
    centerText: '',
    animate: true,
    animationDuration: '1s',
    progressColor: CONSTANTS.COLOR.YELLOW,
    bgColor: '#404040',
    textColor: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
    size: '200',
    lineWidth: '25',
    percentSpacing: 10,
    textStyle: {font: '8rem mars'},
    styles: {}
  }

  get bgText() {
    const {centerText, textColor, textStyle} = this.props;
    const textStyle2 = {
      font: '8rem mars',
      strokeWidth: 20,
      stroke: '#000',
    }

    return (
      <text style={textStyle2} fill={textColor} x={radius} y={radius} textAnchor="middle"
            dominantBaseline="central">
        {centerText}
      </text>
    );
  }

  get text() {
    const {centerText, textColor, textStyle} = this.props;

    return (
      <text style={textStyle} fill={textColor} x={radius} y={radius} textAnchor="middle"
            dominantBaseline="central">
        {centerText}
      </text>
    );
  }

  render() {
    const {text, bgText} = this;
    const {styles, progress, size, bgColor, progressColor, lineWidth, animate, animationDuration, roundedStroke, responsive, onAnimationEnd} = this.props;
    const strokeDashoffset = getOffset(progress);
    const transition = animate ? `stroke-dashoffset ${animationDuration} ease-out` : undefined;
    const strokeLinecap = roundedStroke ? 'round' : 'butt';
    const svgSize = responsive ? '100%' : size;

    return (
      // <svg style={styles} width={svgSize} height={svgSize} // original
      <svg style={styles} width={String(parseInt(size) * 1.1)} height={svgSize}
           viewBox="-25 -25 400 400">
        <circle stroke={bgColor} cx="175" cy="175" r="175" strokeWidth={lineWidth} fill="none"/>
        <circle stroke={progressColor} transform="rotate(-90 175 175)" cx="175" cy="175" r="175"
                strokeDasharray="1100" strokeWidth={lineWidth} strokeDashoffset="1100"
                strokeLinecap={strokeLinecap} fill="none" style={{strokeDashoffset, transition}}
                onTransitionEnd={onAnimationEnd}/>
        {bgText}
        {text}
      </svg>
    );
  }
}
