import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {useEffect, useRef, useState} from 'react';
import {HomeIcon} from './icons/HomeIcon';
import {CONSTANTS} from '../constants';
import clsx from 'clsx';
import {CoffeeIcon} from './icons/CoffeeIcon';
import {I18nText} from "./I18nText";
import {i18nUtil} from "../utils/i18n";

type GoalText = {
  text: I18nText,
  character?: string,
}
const goalTexts: GoalText[] = [
  {
    text: {
      ja: 'べんきょうねっしんで　なによりだな。',
      en: 'heya. you\'ve been studious, huh?',
    },
    character: 's',
  },
  {
    text: {
      ja: 'よくできました！',
      en: 'Splendid! little one.',
    },
  },
  {
    text: {
      ja: 'おちゃでも　いかがかな？',
      en: 'Would you like a cup of tea?',
    },
  },
  {
    text: {
      ja: 'LOVEの　いみは　「LEVEL of VOCABULARY ENLARGEMENT」ごいりょくレベル　だよ。',
      en: 'LOVE is "LEVEL of VOCABULARY ENLARGEMENT". don\'t sweat it, kid.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'オイラてきには　えいごおぼえるとか　どーでもいいけどな。',
      en: 'i dont really care about learning Japanese.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'たまには　かたのちから　ぬけよ。　それが　ほんとの…　ホネやすめ…　なんつって。',
      en: 'wow, sounds like you\'re really working yourself... down to the bone.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'うまくいったな。',
      en: 'that worked out, huh?',
    },
    character: 's',
  },
  {
    text: {
      ja: 'ほらな？「えいご」は　みんな　だいすき　なんだ。',
      en: 'i\'m tellin\' ya. everyone likes Japanese.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'オイラは　アンタを　おうえん　してるぜ',
      en: 'i\'m rootin\' for ya, kid.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'よう。　チョーシはどうだい？',
      en: 'oh hey, what\'s up?',
    },
    character: 's',
  },
  {
    text: {
      ja: 'オイラが　たすける　までも　なかったな。',
      en: 'you didn\'t even need my help.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'オイラ　きほんてきに　なんも　したくないから。',
      en: 'i love doing absolutely nothing.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'EXPは「EXperience Point」。あんしん　しなよ。　けいけんち　だぜ。',
      en: 'don\'t sweat it, kid. EXP is just "EXperience Point"',
    },
    character: 's',
  },
  {
    text: {
      ja: 'アンタ　そうとう　えいごが　とくい　なんだな。',
      en: 'you must be really good at Japanese, huh?',
    },
    character: 's',
  },
  {
    text: {
      ja: 'また　いっしょに　やろうぜ。',
      en: 'let\'s hang out again sometime.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'もう　べんきょうなんて　やめようぜ？',
      en: 'let\'s quit studying.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'アンタ　べんきょう　すきなんだな。',
      en: 'i guess despite all that, you still like Japanese, huh...?',
    },
    character: 's',
  },
  {
    text: {
      ja: 'オイラも　ランプ　あいてに　えいごのべんきょう　してるんだ。',
      en: 'that lamp is perfect for practicing Japanese.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'え？　オイラが　えいごのべんきょう…？　そんなこと　いった　おぼえは　ないぜ。',
      en: 'Japanese? i don\'t remember saying, kid.',
    },
    character: 's',
  },
  {
    text: {
      ja: 'どうかしたか？　オイラ　しごとを　かけもち　してんのさ。',
      en: 'what? haven\'t you seen a guy with two jobs before?',
    },
    character: 's',
  },
  {
    text: {
      ja: 'このあと　どうするんだワン…？',
      en: 'What will you do...?',
    },
  },
  {
    text: {
      ja: 'ねながら　おうえん　してるワン！',
      en: 'I\'m cheering you on in my sleep!!',
    },
  },
  {
    text: {
      ja: '(あなたは　ニンゲンにしては　べんきょうねっしんだと　きいたケロ)',
      en: 'Ribbit, ribbit. (I have heard you are quite studious, for a human...)',
    },
  },
  {
    text: {
      ja: '(えいごとの　バトルは　どんなちょうしケロ？)',
      en: 'Ribbit, ribbit. (How are you doing Japanese?)',
    },
  },
  {
    text: {
      ja: '(あなたは　ごいりょくのある　りっぱなひとに　せいちょう　したケロ)',
      en: 'Ribbit, ribbit. (You seem like you have substantial vocabulary.)',
    },
  },
];

const useStyles = makeStyles({
  '@keyframes vibe': {
    '0%': {transform: 'translate(0px, 0px) rotateZ(0deg)'},
    '25%': {transform: 'translate(2px, 2px) rotateZ(1deg)'},
    '50%': {transform: 'translate(0px, 2px) rotateZ(0deg)'},
    '75%': {transform: 'translate(2px, 0px) rotateZ(-1deg)'},
    '100%': {transform: 'translate(0px, 0px) rotateZ(0deg)'},
  },
  '@keyframes slide': {
    '0%': {transform: 'translateX(0px)'},
    '100%': {transform: `translateX(${window.innerWidth + 40}px)`},
  },
  '@keyframes horizontalRoundTrip': {
    '0%': {transform: 'translateX(0px)'},
    '25%': {transform: `translateX(-${window.innerWidth / 2 - 20}px)`},
    '50%': {transform: 'translateX(0px)'},
    '75%': {transform: `translateX(${window.innerWidth / 2 - 20}px)`},
    '100%': {transform: 'translateX(0px)'},
  },
  '@keyframes horizontalFlip': {
    '0%': {transform: 'rotateY(0deg)'},
    '24.9%': {transform: 'rotateY(0deg)'},
    '25%': {transform: 'rotateY(180deg)'},
    '74.9%': {transform: 'rotateY(180deg)'},
    '75%': {transform: 'rotateY(0deg)'},
    '100%': {transform: 'rotateY(0deg)'},
  },
  '@keyframes verticalRoundTrip': {
    '0%': {transform: 'translateY(0px)'},
    '50%': {transform: `translateY(${window.innerHeight - 100}px)`},
    '100%': {transform: 'translateY(0px)'},
  },
  '@keyframes expandHeight': {
    '0%': {height: '0%'},
    '100%': {height: '120%'},
  },
  '@keyframes expandAndCollapseHeight': {
    '0%': {height: '0%'},
    '40%': {height: '120%'},
    '60%': {height: '120%'},
    '100%': {height: '0%'},
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  adView: {
    margin: '30px 0 20px 0',
    zIndex: 9,
  },
  slide: {
    width: '100%',
    padding: 15,
    color: '#fff',
  },
  buttons: {
    color: '#fff',
    background: '#000',
    fontFamily: 'determination, undertale-ja',
    border: `4px solid ${CONSTANTS.COLOR.WHITE}`,
    padding: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    marginLeft: '8px',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  buttonText: {
    padding: '0 8px',
    animation: `$vibe .1s  infinite`,
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap',
  },
  goalText: {
    fontSize: '40px',
    color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
    marginTop: '30px',
    marginBottom: 0,
    fontFamily: 'determination',
  },
  chosenGoalText: {
    fontFamily: 'determination, undertale-ja',
    color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
    marginTop: 0,
    marginLeft: 16,
    marginRight: 16,
  },
  sans: {
    fontFamily: 'sans-ja',
  },
  sansEn: {
    fontFamily: 'sans-en',
  },
  temmieWrapper: {
    position: 'absolute',
    animation: `$slide 30s infinite`,
    animationTimingFunction: 'linear',
  },
  temmie: {
    animation: `$vibe .1s infinite`,
    width: 40,
  },
  lesserDog: {
    position: 'absolute',
    backgroundColor: 'rgb(254, 253, 254)',
    backgroundImage: 'url(/images/lesserdog.gif)',
    backgroundRepeat: 'no-repeat',
    width: 46,
    height: '0%',
    bottom: 0,
    zIndex: -1,
  },
  lesserDog2: {
    position: 'absolute',
    backgroundColor: CONSTANTS.COLOR.WHITE,
    backgroundImage: 'url(/images/lesserdog.gif)',
    backgroundRepeat: 'no-repeat',
    width: 46,
    height: '0%',
    top: '-20%',
    zIndex: -1,
    transform: 'rotate(180deg)',
    marginLeft: 120,
  },
  lesserDogExpand: {
    animation: `$expandHeight 120s`,
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
  },
  hangedDogWrapper: {
    position: 'absolute',
    backgroundImage: 'url(/images/rope.png)',
    backgroundRepeat: 'repeat-y',
    width: 83,
    height: '0%',
    top: '-20%',
    zIndex: -1,
    marginRight: 140,
    backgroundPositionY: 'bottom',
    backgroundPositionX: 'center',
    backgroundSize: 4,
    display: 'flex',
    alignItems: 'flex-end',
  },
  hangedDog: {
    backgroundImage: 'url(/images/dog_hanged.gif)',
    backgroundRepeat: 'no-repeat',
    width: 83,
  },
  fallHangedDog: {
    animation: `$expandAndCollapseHeight 60s`,
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
  },
  walkDogWrapper: {
    position: 'absolute',
    top: '0%',
    zIndex: -1,
    animation: `$verticalRoundTrip 10s infinite`,
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
  },
  walkDogFlipper: {
    animation: `$horizontalFlip 7s infinite`,
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
    width: 60,
  },
  walkDog: {
    animation: `$horizontalRoundTrip 7s infinite`,
    animationTimingFunction: 'linear',
    animationFillMode: 'forwards',
  },
});
const texts = {
  home: {
    en: 'home',
    ja: 'ホームへ\nもどる',
  },
  break: {
    en: 'break',
    ja: 'ひといき\nいれる',
  }
}
let classes;

type GoalSlideProps = {
  key: string,
  temmie: number[],
  setTemmie: Function,
  lesserDogUp: boolean,
  setLesserDogUp: Function,
  lesserDogDown: boolean,
  setLesserDogDown: Function,
  hangedDog: boolean,
  setHangedDog: Function,
  walkDog: boolean,
  setWalkDog: Function,
  level: number,
  onPrimaryButtonClick: (event: React.MouseEvent<HTMLInputElement>) => void,
  displayLanguage: string,
}

const GoalSlideRenderer: React.FC<GoalSlideProps> = (props) => {
  classes = useStyles();
  const {
    onPrimaryButtonClick,
    temmie,
    setTemmie,
    lesserDogUp,
    setLesserDogUp,
    lesserDogDown,
    setLesserDogDown,
    hangedDog,
    setHangedDog,
    walkDog,
    setWalkDog,
    level,
    displayLanguage,
  } = props;

  const [goalText, setGoalText] = useState<GoalText>({text: {ja: '', en: ''}});
  const root = useRef(null);

  useEffect(() => {
    setGoalText(chooseGoalTexts());
  }, []);

  useEffect(() => {
    const html = document.getElementById('chocolateAdView').innerHTML;
    document.getElementById('goaledChocolateAdView').innerHTML = html;
  }, []);

  const chooseGoalTexts = (): GoalText => {
    return goalTexts[(new Date()).getTime() % goalTexts.length];
  };

  const temmieComponent = (top, key) => {
    return (
      <div
        key={key}
        style={{
          top: top,
          left: root.current.clientWidth - 38,
        }}
        className={classes.temmieWrapper}>
        <img src={'/images/temmieface.png'}
             className={classes.temmie}/>
      </div>
    );
  };

  const walkDogComponent = () => {
    return (
      <div
        className={classes.walkDogWrapper}
      >
        <div
          className={classes.walkDog}
        >
          <img src={'/images/dog_walk.gif'}
               className={classes.walkDogFlipper}
          />
        </div>
      </div>
    );
  };

  const teaBreak = () => {
    switch (level % 4) {
      case 0:
        addTemmie();
        break;
      case 1:
        fallHangedDog();
        break;
      case 2:
        deployDog();
        break;
      case 3:
      default:
        expandLesserDogUp();
    }
  };

  const expandLesserDogUp = () => {
    setLesserDogUp(true);
  };

  const expandLesserDogDown = () => {
    setLesserDogDown(true);
  };

  const addTemmie = () => {
    if (temmie.length >= 20) {
      return;
    }
    const getRandomInt = (max) => {
      return Math.floor(Math.random() * Math.floor(max));
    };
    setTemmie([...temmie, getRandomInt(root.current.clientHeight - 40)]);
  };

  const fallHangedDog = () => {
    setHangedDog(true);
  };

  const deployDog = () => {
    setWalkDog(true);
  };

  const sansFont = () => {
    return displayLanguage === 'ja' ? classes.sans : classes.sansEn
  }

  return (
    <div className={classes.root}
         ref={root}>
      <p className={classes.goalText}>GOAL!</p>
      <p
        className={clsx(classes.chosenGoalText,
          goalText.character === 's' ? sansFont() : '')}
      >{i18nUtil.choose(goalText.text, displayLanguage)}</p>
      <div id={'goaledChocolateAdView'} className={classes.adView}/>
      <div
        className={classes.slide}
      >
        <div
          className={classes.buttons}
        >
          <div className={classes.button}>
            <HomeIcon
              onClick={onPrimaryButtonClick}
            />
            <span
              onClick={onPrimaryButtonClick}
              className={classes.buttonText}><I18nText text={texts.home}/></span>
          </div>
          <div className={classes.button}>
            <CoffeeIcon
              onClick={teaBreak}
            />
            <span
              onClick={teaBreak}
              className={classes.buttonText}><I18nText text={texts.break}/></span>
          </div>
        </div>
      </div>
      <div
        className={clsx(
          classes.lesserDog,
          lesserDogUp && classes.lesserDogExpand,
        )}
        onAnimationEnd={expandLesserDogDown}/>
      <div
        className={clsx(
          classes.lesserDog2,
          lesserDogDown && classes.lesserDogExpand,
        )}
      />
      <div
        className={clsx(
          classes.hangedDogWrapper,
          hangedDog && classes.fallHangedDog,
        )}
        onAnimationEnd={() => setHangedDog(false)}
      >
        <img src={'/images/dog_hanged.gif'}
             className={classes.hangedDog}/>
      </div>
      {walkDog && walkDogComponent()}
      {temmie.map((p, index) => temmieComponent(p, index))}
    </div>
  );
};

export default GoalSlideRenderer;
