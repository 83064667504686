export const CONSTANTS = {
  PATH: {
    ROOT: '/',
    HOME: '/home',
    SETTINGS: '/settings',
  },
  SETTING: {
    MODE: {
      EN: 'en',
      JA: 'ja',
    },
  },
  QUESTION: {
    COUNT: 10,
  },
  DATE: {
    FORMAT: {
      YMD: 'YYYYMMDD',
    },
  },
  FONT: {
    PRIMARY_TEXT_COLOR: '#fff',
  },
  COLOR: {
    WHITE: '#FFF',
    BLACK: '#000',
    ORANGE: '#FF893C',
    YELLOW: '#fffe55',
    DAMAGED_RED: '#AF2318',
  },
  SPECIAL_NAMES: {
    ALPHYS: {
      en: 'D-don\'t do that.',
      JA: 'ダ・・・　ダメだよっ！',
      FORBIDDEN: true,
    },
    ASGORE: {
      en: 'You cannot.',
      JA: 'そのなまえは　ダメだ。',
      FORBIDDEN: true,
    },
    ASRIEL: {
      en: '...',
      JA: '...',
      FORBIDDEN: true,
    },
    FLOWEY: {
      en: 'I already CHOSE that name.',
      JA: 'そのなまえは　ボクだけのもの。',
      FORBIDDEN: true,
    },
    SANS: {
      en: 'nope.',
      JA: 'ダメだぜ。',
      FORBIDDEN: true,
    },
    TORIEL: {
      en: 'I think you should think of your own name, my child.',
      JA: 'ちゃんと　なまえを　かんがえるのよ。',
      FORBIDDEN: true,
    },
    UNDYNE: {
      en: 'Get your OWN name!',
      JA: 'ひとの　なまえを　パクるな！',
      FORBIDDEN: true,
    },
    FRISK: {
      en: 'WARNING: This name will make your life hell. Proceed anyway?',
      JA: 'けいこく：このなまえは　じごくへの　いりぐち…　このまま　はじめますか？',
    },
    MURDER: {
      en: 'That\'s a little on-the-nose, isn\'t it...?',
      JA: 'こんな　なまえで　いいんですか？',
    },
    MERCY: {
      en: 'That\'s a little on-the-nose, isn\'t it...?',
      JA: 'こんな　なまえで　いいんですか？',
    },
    AAAAAA: {
      en: 'Not very creative...?',
      JA: 'ほんとに　これでいいの…？',
    },
    AARON: {
      en: 'Is this name correct? ; )',
      JA: 'このなまえで　よろしいですか？(^_-)☆',
    },
    ALPHY: {
      en: 'Uh.... OK?',
      JA: 'う…　うん　いいよ…',
    },
    CATTY: {
      en: 'Bratty! Bratty! That\'s MY name!',
      JA: 'アリゲッティ！　みて！　アタシのなまえ　だしぃ～！',
    },
    BRATTY: {
      en: 'Like, OK I guess.',
      JA: 'べつに　いいけどぉ～？',
    },
    BPANTS: {
      en: 'You are really scraping the bottom of the barrel.',
      JA: '…なまえのネタぎれ？',
    },
    CHARA: {
      en: 'The true name.',
      JA: 'ほんとうのなまえ。',
    },
    GERSON: {
      en: 'Wah ha ha! Why not?',
      JA: 'ワッハッハッ！　そりゃあいい！',
    },
    JERRY: {
      en: 'Jerry.',
      JA: 'ジェリー。',
    },
    METTA: {
      en: 'OOOOH!!! ARE YOU PROMOTING MY BRAND?',
      JA: 'おお！！キミ！！　せんでん　してくれるのかい？',
    },
    METT: {
      en: 'OOOOH!!! ARE YOU PROMOTING MY BRAND?',
      JA: 'おお！！キミ！！　せんでん　してくれるのかい？',
    },
    MTT: {
      en: 'OOOOH!!! ARE YOU PROMOTING MY BRAND?',
      JA: 'おお！！キミ！！　せんでん　してくれるのかい？',
    },
    NAPSTA: {
      en: '............(They\'re powerless to stop you.)',
      JA: '…　…　…　…（とめるちからは　ない）',
    },
    BLOOKY: {
      en: '............(They\'re powerless to stop you.)',
      JA: '…　…　…　…（とめるちからは　ない）',
    },
    PAPYRU: {
      en: 'I\'LL ALLOW IT!!',
      JA: 'オレさまは　べつに　いいよッ！',
    },
    SHYREN: {
      en: '...?',
      JA: '...?',
    },
    TEMMIE: {
      en: 'hOI!',
      JA: 'ホィ!',
    },
    WOSHUA: {
      en: 'Clean name.',
      JA: 'せいけつな　なまえ。',
    },
    GASTER: {
      en: '',
      JA: '',
    },
  },
  MAX_WORD_COUNT: 6745,
};
