import React from 'react';
import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import {NavLink} from 'react-router-dom';
import {CONSTANTS} from "../constants";
import {I18nText} from "./I18nText";

const useStyles = makeStyles((theme) =>
  createStyles({
    '@keyframes vibe': {
      '0%': {transform: 'translate(0px, 0px) rotateZ(0deg)'},
      '25%': {transform: 'translate(2px, 2px) rotateZ(1deg)'},
      '50%': {transform: 'translate(0px, 2px) rotateZ(0deg)'},
      '75%': {transform: 'translate(2px, 0px) rotateZ(-1deg)'},
      '100%': {transform: 'translate(0px, 0px) rotateZ(0deg)'},
    },
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#000',
    },
    title: {
      display: 'flex',
      flexDirection: 'column',
    },
    dog: {
      width: 80,
      alignSelf: 'flex-end',
      marginRight: 10,
    },
    de: {
      fontFamily: 'undertale-ja',
      fontSize: 16,
      color: CONSTANTS.COLOR.WHITE,
      display: 'inline-block',
      animation: `$vibe .1s  infinite`,
      marginRight: 8,
      fontWeight: 900,
    },
    logo: {
      margin: 0,
      width: 300,
    },
    subTitle: {
      color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
      fontFamily: 'undertale-ja',
      fontSize: '16',
      margin: 0,
      marginBottom: '40px',
      textAlign: 'center',
    },
    progressBar: {
      marginLeft: 0,
    },
    navLink: {
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'center',
    },
    soul: {
      width: 24,
      height: 24,
      marginTop: 6,
      marginBottom: 6,
    },
    goToHome: {
      width: '146px',
      color: '#fff',
      padding: '5px',
      display: 'flex',
      border: '4px solid #FF893C',
      alignItems: 'center',
      background: 'url(/images/commands/start-orange.png)',
      backgroundPositionY: 'center',
      backgroundSize: 'contain',
      backgroundPositionX: 'right',
      backgroundRepeat: 'no-repeat',
    },
    goToHomeText: {
      width: '80px',
      marginLeft: '8px',
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      marginBottom: 16,
    },
    footerSubTitle: {
      color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
      fontSize: '12px',
      fontFamily: 'determination, undertale-ja',
      margin: 0,
      '& a': {
        color: CONSTANTS.FONT.PRIMARY_TEXT_COLOR,
      },
    }
  }),
);

const texts = {
  throughEnglish: {
    en: 'learn japanese through UNDERTALE',
    ja: 'で おぼえる えいご',
  },
  displayLanguage: {
    en: 'language',
    ja: 'げんごせってい',
  },
  supportBrowser: {
    en: 'supported browser',
    ja: 'サポートブラウザ',
  }
}

type LandingProps = {
  displayLanguage: string | null,
  onLanguageChanged: Function,
}

export const Landing: React.FC<LandingProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const {displayLanguage, onLanguageChanged} = props

  return (
    <div className={classes.root}>
      <div>
        <div
          className={classes.title}
        >
          <img src={'/images/dog_sleep.gif'} alt={'dog'}
               className={classes.dog}
          />
          <img src={'/images/logo.png'} alt={'UNDERTALE'}
               className={classes.logo}
          />
        </div>
        <p className={classes.subTitle}>
        <span
          className={classes.de}
        ><I18nText text={texts.throughEnglish}/></span>
        </p>
        <NavLink to={'/home'} className={classes.navLink} exact>
          <p
            className={classes.goToHome}
          >
            <img src={'/images/soul.png'} className={classes.soul}/>
            <span className={classes.goToHomeText}></span>
          </p>
        </NavLink>
      </div>
      <div
        className={classes.footer}
      >
        <p className={classes.footerSubTitle}><I18nText
          text={texts.displayLanguage}/><span>: </span>
          <select value={displayLanguage}
                  onChange={(e) => onLanguageChanged(e.currentTarget.value)}>
            <option value={'ja'}>にほんご</option>
            <option value={'en'}>English</option>
          </select>
        </p>
        <p className={classes.footerSubTitle}><I18nText
          text={texts.supportBrowser}
        />: Google Chrome</p>
        <p className={classes.footerSubTitle}>twitter: <a href={'https://twitter.com/undertaledog'}
                                                          target={'_blank'}
                                                          rel={'noopener noreferrer'}>@undertaledog</a>
        </p>
      </div>
    </div>
  );
};
