import React, {useEffect, useState} from 'react';
import {Name} from "../components/Name";
import db from "../db";
import {CONSTANTS} from "../constants";
import useReactRouter from "use-react-router";
import {setName as setNameAction} from "../modules/setting/actions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../rootReducer";

const NameContainer: React.FC = () => {

  const [name, setName] = useState('');
  const [specialNameText, setSpecialNameText] = useState('');
  const [isConfirm, setConfirm] = useState(false);
  const [isForbidden, setForbidden] = useState(false);
  const dispatch = useDispatch();
  const {history} = useReactRouter();
  const settingStore = useSelector((state: RootState) => state.settings);

  useEffect(() => {
    setName(settingStore.name || '')
  }, [settingStore.name])

  const input = (char) => {
    if (`${name}${char}`.toUpperCase() === 'GASTER') {
      history.replace(CONSTANTS.PATH.ROOT);
      return
    }
    if (name.length >= 6) {
      return
    }
    setName(`${name}${char}`)
  }

  const backspace = () => {
    if (!name.length) {
      return
    }
    setName(name.substring(0, name.length - 1))
  }

  const confirm = () => {
    setConfirm(true)
    setForbidden(CONSTANTS.SPECIAL_NAMES[name.toUpperCase()]?.FORBIDDEN)
    setSpecialNameText(CONSTANTS.SPECIAL_NAMES[name.toUpperCase()]?.JA)
  }

  const back = () => {
    setConfirm(false)
    setForbidden(false)
  };

  const accept = () => {
    (async () => {
      dispatch(setNameAction(name))
      await db.settings.update(1, {
        name
      });
    })();
    history.replace(CONSTANTS.PATH.HOME);
  };

  return (
    <Name
      displayLanguage={settingStore.displayLanguage}
      name={name}
      handleInput={input}
      handleBackspace={backspace}
      handleConfirm={confirm}
      handleAccept={accept}
      handleBack={back}
      isConfirm={isConfirm}
      isForbidden={isForbidden}
      specialNameText={specialNameText}
    />
  );
};

export default NameContainer
