import {createStyles, makeStyles, useTheme} from '@material-ui/core/styles';
import dayjs from 'dayjs';
import React, {useEffect, useState} from 'react';
import useReactRouter from 'use-react-router';
import {CONSTANTS} from '../constants';
import db from '../db';
import ProgressBar from './ProgressBar';
import {History} from "../entities/history";
import {Level} from "../entities/level";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../rootReducer";
import {SettingState} from "../modules/setting/types";
import {setTriedCount} from "../modules/history/actions";
import {init, setLastLaunchedDate} from "../modules/setting/actions";

const useStyles = makeStyles((theme) =>
  createStyles({
    '@keyframes vibe': {
      '0%': {transform: 'translate(0px, 0px) rotateZ(0deg)'},
      '25%': {transform: 'translate(2px, 2px) rotateZ(1deg)'},
      '50%': {transform: 'translate(0px, 2px) rotateZ(0deg)'},
      '75%': {transform: 'translate(2px, 0px) rotateZ(-1deg)'},
      '100%': {transform: 'translate(0px, 0px) rotateZ(0deg)'},
    },
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loadingText: {
      color: CONSTANTS.COLOR.WHITE,
      fontFamily: 'determination',
      fontWeight: 900,
      animation: `$vibe .1s  infinite`,
    },
    progressWrapper: {
      width: '100%',
      height: 4,
    },
    progressBar: {
      marginLeft: 0,
    },
  }),
);

const Init: React.FC = () => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [progress, setProgress] = useState(0);
  const {history} = useReactRouter();
  const settingStore: SettingState = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();

  useEffect(() => {
    history.replace(CONSTANTS.PATH.HOME);
    initialize()
    return () => {
    }
  }, [settingStore.lastLaunchedDate]);

  const initialize = async () => {

    if (!await hasLevelData()) {
      await saveLevels();
    }

    // fetch word json
    // if db.words.countがconstants.MAX_WORD_COUNTより少ないとき単語ファイル取得
    if (await needsWordUpdate()) {
      const words = await (await fetch('/words.json')).json();
      // insert into indexedDB
      await saveWords(words);
    }

    // insert today's history
    await saveHistory();
    await storeHistory();
    await storeSetting();

    launchHome();
  };

  const hasLevelData = async () => {
    const count: Level = await db.levels.count()
    return !!count
  };

  const saveLevels = async () => {
    let index = 0;
    setProgress(0);
    const levels = await (await fetch('/levels.json')).json();
    return db.transaction('rw', db.levels, async () => {
      for (let level of levels) {
        db.levels.put(
          {
            level: level.level,
            experience: level.experience,
          });
        if (index % 100 === 0) {
          setProgress(index / levels.length * 100);
        }
        index++;
      }
    }).catch(e => {
      console.error(e.stack || e);
    });
  };

  const saveWords = (words) => {
    let index: number = 0;
    setProgress(0);
    return db.transaction('rw', db.words, async () => {
      for (let word of words) {
        if ((await db.words.where({word: word.word}).count()) === 0) {
          db.words.add(
            {
              wordId: index,
              word: word.word,
              mean: word.mean,
              character: word.c,
            });
        }
        if (index % 100 === 0) {
          setProgress(index / words.length * 100);
        }
        index++;
      }
    }).catch(e => {
      console.error(e.stack || e);
    });
  };

  const saveHistory = async () => {
    const today: string = dayjs().format(CONSTANTS.DATE.FORMAT.YMD);
    const record: History = await db.histories.get({date: today});
    if (record) {
      return record;
    }
    await db.histories.put({
      date: today,
      triedCount: 0,
      goaledCount: 0,
    });
  };

  const storeHistory = async () => {
    const histories = await db.histories.toArray();
    const triedCount = histories.reduce(
      (accum, current) => accum + current.triedCount, 0);
    dispatch(setTriedCount(triedCount))
  };

  const storeSetting = async () => {
    const setting = await db.settings.get(1);
    dispatch(init(setting))
  };

  const needsWordUpdate = async (): Promise<boolean> => {
    const currentWordCount = await db.words.count();
    return currentWordCount < CONSTANTS.MAX_WORD_COUNT
  }

  const launchHome = () => {
    // lastLaunchDateの状態が今日の日付に更新されることでApp.tsx内でhomeへとルーティングされる
    dispatch(setLastLaunchedDate(dayjs().format(CONSTANTS.DATE.FORMAT.YMD)))
  }

  return (
    <div className={classes.root} onClick={initialize}>
      <p className={classes.loadingText}>
        now loading...
      </p>
      <div
        className={classes.progressWrapper}
      >
        <ProgressBar progress={progress}/>
      </div>
    </div>
  );
};

export default Init
