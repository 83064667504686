export class Setting {
  mode: string; // 日英どちらを隠すか
  language: string; // 日英どちらを読み上げるか
  displayLanguage: string; // サイト表示言語
  voice: string;
  rate: number;
  japaneseVoice: string;
  japaneseRate: number;
  name: string;
}
