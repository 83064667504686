import React, {useEffect} from 'react';
import {Landing} from "../components/Landing";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../rootReducer";
import {init, setDisplayLanguage} from "../modules/setting/actions";
import db from "../db";

const LandingContainer: React.FC = () => {
  useEffect(() => {
    storeSetting()
    return () => {
    }
  }, []);

  const storeSetting = () => {
    (async () => {
      const setting = await db.settings.get(1);
      dispatch(init(setting))
    })();
  };

  const settingStore = useSelector((state: RootState) => state.settings);
  const dispatch = useDispatch();


  const acceptDisplayLanguage = (displayLanguage: string) => {
    (async () => {
      dispatch(setDisplayLanguage(displayLanguage))
      await db.settings.update(1, {
        displayLanguage
      });
    })();
  };

  return (
    <Landing displayLanguage={settingStore.displayLanguage}
             onLanguageChanged={acceptDisplayLanguage}
    />
  );
};

export default LandingContainer
