import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Home} from '../components/Home';
import db from '../db';
import {Level} from "../entities/level";
import {RootState} from "../rootReducer";
import {hideAnswer, speech} from "../modules/word/actions";
import {setCurrentLevel, setNextLevel} from "../modules/level/actions";

const HomeContainer: React.FC = () => {
  const [totalGoaledCount, setTotalGoaledCount] = useState(0);
  const [totalStudyDays, setTotalStudyDays] = useState(0);
  const historyStore = useSelector((state: RootState) => state.histories);
  const levelStore = useSelector((state: RootState) => state.levels);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(hideAnswer())
    // chromeでは音声リストを取得するためには一度speechする必要があるので空文字を喋らせておく
    // https://techblog.istyle.co.jp/archives/3924
    dispatch(speech({text: ''}));
  }, []);

  useEffect(() => {
    (async () => {
      const currentLevel: Level = await db.levels.where('experience')
      .belowOrEqual(historyStore.triedCount)
      .reverse()
      .first();
      const nextLevel: Level = await db.levels.where('experience')
      .above(historyStore.triedCount)
      .first();
      dispatch(setCurrentLevel(currentLevel));
      dispatch(setNextLevel(nextLevel));
    })();
    return () => {
    }
  }, []);

  // totalGoaledCount
  useEffect(() => {
    (async () => {
      setTotalGoaledCount(await calculateGoaledCount());
      setTotalStudyDays(await calculateStudyDays());
    })();
    return () => {
    }
  }, []);

  const calculateProgress = (): number => {
    const needExperience = levelStore.nextLevel.experience -
      levelStore.currentLevel.experience;
    const gotExperience = historyStore.triedCount -
      levelStore.currentLevel.experience;
    const progress: number = gotExperience / needExperience * 100;
    return progress || 0;
  };

  const calculateGoaledCount = async (): Promise<number> => {
    const totalGoaledCount: number = (await db.histories.toArray())
    .reduce((first, second) => first + second.goaledCount, 0);
    return totalGoaledCount;
  };

  const calculateStudyDays = async (): Promise<number> => {
    const totalStudyDays: number = (await db.histories
    .where('triedCount')
    .above(0)
    .count());
    return totalStudyDays;
  };

  return (
    <Home totalGoaledCount={totalGoaledCount}
          totalStudyDays={totalStudyDays}
          levelProgress={calculateProgress()}
          currentLevel={levelStore.currentLevel.level}
    />
  );
};

export default HomeContainer;
