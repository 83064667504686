import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Study} from '../components/Study';
import {CONSTANTS} from '../constants';
import db from '../db';
import {setMean, setQuestions, setWord} from '../modules/word/actions';
import {arrayUtil} from '../utils/array';
import {Word} from "../entities/word";
import useReactRouter from "use-react-router";
import {SettingState} from "../modules/setting/types";
import {RootState} from "../rootReducer";

const StudyContainer: React.FC = (props) => {
  const [isQuestionsPrepared, setQuestionsPrepared] = useState<boolean>(false);
  const {location} = useReactRouter();
  const dispatch = useDispatch();
  const settingStore: SettingState = useSelector((state: RootState) => state.settings);

  useEffect(() => {
    (async () => {
      if (location.pathname !== '/home') {
        return
      }
      const randomIndexes: number[] = await generateRandomIndexes(
        CONSTANTS.QUESTION.COUNT);
      const questions: Word[] = await db.words.where('wordId')
      .anyOf(randomIndexes)
      .toArray();
      dispatch(setWord(questions[0].word.replace('#name#', settingStore.name)))
      dispatch(setMean(questions[0].mean.replace('#name#', settingStore.name)))
      dispatch(setQuestions(questions))
      setQuestionsPrepared(true);
    })();
    return () => {
    }
  }, [location.pathname]); // homeを開くたびに問題を更新する

  const generateRandomIndexes: (number) => Promise<number[]> = async (wordCount) => {
    let count: number;
    count = await db.words.count();
    const wordIds: number[] = [...Array(count).keys()]
    return arrayUtil.shuffle(wordIds).slice(0, wordCount);
  };

  return (
    <Study
      isQuestionsPrepared={isQuestionsPrepared}
      children={props.children}
    />
  );
};

export default StudyContainer
